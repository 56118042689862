import React from "react";
import styles from "./SideBar.module.scss";
import Profile from "./index";
import IconClose from "../common/icons/IconClose";

const Sidebar = (
  {
    hideSidebar,
    currentItem,
    isOpened,
  },
) => {

  const closeSidebar = () => {
    hideSidebar();
  };

  return (
    <>
      <div
        className={`${styles.mask} ${isOpened ? "" : styles.hide}`}
        onClick={() => closeSidebar()}
      ></div>
      <div className={`${styles.sidebar} ${isOpened && styles.display}`}>
        <div onClick={() => closeSidebar()} className={styles.close}>
          <IconClose size={14} />
        </div>

        <Profile contact={currentItem} />

      </div>
    </>
  );
};

export default Sidebar;
