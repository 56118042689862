import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";

import {
  AppsTab,
  CollaboratorsTab,
  RecordsTab,
  ConstactsTab,
  MyPortals,
  MemberShipsTab,
} from "./tabs";
import LeftBanner from "./LeftBanner";
import Tabs from "../common/Tabs";
import CollaboratorForm from "../Join/Collaborator/CollaboratorForm";
import CollaboratorImport from "../Join/Collaborator/CollaboratorImport";
import CollaboratorExpress from "../Join/Collaborator/CollaboratorExpress";
import ClientForm from "../Join/Client/ClientForm";
import ClientImport from "../Join/Client/ClientImport";
import ClientExpress from "../Join/Client/ClientExpress";
import ContactForm from "../Join/Contact/ContactForm";
import ContactImport from "../Join/Contact/ContactImport";
import UserExpressList from "../Join/Collaborator/UserExpressList";
import MembershipForm from "./tabs/ManageMembership/MembershipForm";

import UpgradeModal from "./UpgradeModal";
import styles from "./Settings.module.scss";

import _ from "../../i18n";
import { setIsSuccessPayment } from "../../store";
import Sidebar from "../Profile/SideBar";


const Settings = (props) => {
  // const mainTabs = [
  //   "portals",
  //   "apps",
  //   "collaborators",
  //   // "customer_records",
  //   "other_contacts",
  //   // "specialty",
  // ];
  const isSuccessPayment = useSelector(
    (state) => state.params.isSuccessPayment,
  );
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const dispatch = useDispatch();
  const [sideBar, setSideBar] = useState("DEFAULT");
  const [currentItem, setCurrentItem] = useState(null);
  const [emailValue, setEmailValue] = useState("");
  const [nbCollaborators, setNbCollaborators] = useState(0);
  const [refreshCollabList, setRefreshCollabList] = useState(false);
  const [refreshClientList, setRefreshClientList] = useState(false);
  const [refreshContactList, setRefreshContactList] = useState(false);
  const [refreshMembershipList, setRefreshMembershipList] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [openedTabs, setOpenedTabs] = useState({
    apps: false,
    collaborators: false,
    customer_records: false,
    other_contacts: false,
    memberships: false,
    // specialty: false,
  });
  const [mainTabs, setMainTabs] = useState([]);
  const auth = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = auth;
  const components = {
    portals: MyPortals,
    apps: AppsTab,
    collaborators: CollaboratorsTab,
    customer_records: RecordsTab,
    other_contacts: ConstactsTab,
    memberships: MemberShipsTab,
    // specialty: SpecialtyTab,
  };

  useEffect(() => {
    if (fiduciary) {
      if (
        fiduciary.uaSettings &&
        fiduciary.uaSettings.allowedTabs &&
        fiduciary.uaSettings.allowedTabs.length > 0
      ) {
        let tab = ["portals"];
        if (fiduciary.uaMode !== "COMMUNITY") {
          tab.push("apps");
        }
        if (fiduciary.uaSettings.allowedTabs.includes("COLLABORATOR")) {
          tab.push("collaborators");
        }
        if (fiduciary.uaSettings.allowedTabs.includes("CLIENT")) {
          tab.push("customer_records");
        }
        if (fiduciary.uaSettings.allowedTabs.includes("CONTACT")) {
          tab.push("other_contacts");
        }
        if (fiduciary.uaMode === "COMMUNITY" && fiduciary.id === 1256) {
          tab.push("memberships");
        }
        setMainTabs(tab);
      } else {
        setMainTabs([
          "portals",
          "apps",
          "collaborators",
          // "customer_records",
          // "other_contacts",
        ]);
      }
    }
  }, [fiduciary]);

  useEffect(() => {
    if (isSuccessPayment) {
      toast.success(_("upgrade_successfully"));
      dispatch(setIsSuccessPayment(false));
    }
  }, [isSuccessPayment]);
  useEffect(() => {
    if (sideBar === "CONTACT_FORM" &&
      user.role.type === "LEGAL_REPRESENTATIVE") {
      setIsOpen(true);
    }
  }, [sideBar, currentItem]);

  const handleCollabAfterSave = () => {
    setRefreshCollabList(true);
  };
  const handleClientAfterSave = () => {
    setRefreshClientList(true);
  };
  const handleContactAfterSave = () => {
    setRefreshContactList(true);
  };
  const handleMembershipAfterSave = () => {
    setRefreshMembershipList(true);
  };
  const renderTab = (name, properties) => {
    const DynamicComponent = components[name];
    if (name === "collaborators") {
      return openedTabs.collaborators ? (
        <DynamicComponent
          setCurrentItem={setCurrentItem}
          setSideBar={setSideBar}
          sideBar={sideBar}
          refreshList={refreshCollabList}
          setRefreshList={setRefreshCollabList}
          page="SETTING"
          setNbCollaborators={setNbCollaborators}
          setShowUpgradeModal={setShowUpgradeModal}
        />
      ) : null;
    } else if (name === "customer_records") {
      return openedTabs.customer_records ? (
        <DynamicComponent
          setCurrentItem={setCurrentItem}
          setSideBar={setSideBar}
          sideBar={sideBar}
          refreshList={refreshClientList}
          setRefreshList={setRefreshClientList}
          page="SETTING"
        />
      ) : null;
    } else if (name === "other_contacts") {
      return openedTabs.other_contacts ? (
        <DynamicComponent
          setEmailValue={setEmailValue}
          setCurrentItem={setCurrentItem}
          setSideBar={setSideBar}
          sideBar={sideBar}
          refreshList={refreshContactList}
          setRefreshList={setRefreshContactList}
          page="SETTING"
        />
      ) : null;
    } else if (name === "memberships") {
      return openedTabs.memberships ? (
        <DynamicComponent
          setCurrentItem={setCurrentItem}
          setSideBar={setSideBar}
          sideBar={sideBar}
          refreshList={refreshMembershipList}
          setRefreshList={setRefreshMembershipList}
        />
      ) : null;
    }
      // else if (name === "specialty")
      //   return openedTabs.specialty ? (
      //     <DynamicComponent
      //       setCurrentItem={setCurrentItem}
      //       setSideBar={setSideBar}
      //     />
    //   ) : null;
    else {
      return (
        <DynamicComponent
          setCurrentItem={setCurrentItem}
          setSideBar={setSideBar}
        />
      );
    }
  };

  return (
    <>
      <div className={styles.mobileBanner}>
        Désolé! Cette page n'est pas adaptée aux appareils mobiles
      </div>
      <div
        className={classNames(styles.page, "grid-container")}
        style={{ maxWidth: "90%" }}
      >
        <div
          className="grid-x grid-margin-x"
          style={{ justifyContent: "flex-end" }}
        >
          <div
            className={classNames(
              "cell small-12 medium-6",
              sideBar === "DEFAULT" ? "large-3" : "large-4",
            )}
          >
            {(sideBar === "DEFAULT" || (sideBar === "CONTACT_FORM" &&
              user.role.type === "LEGAL_REPRESENTATIVE")) && <LeftBanner />}
            {sideBar === "COLLABORATOR_FORM" && (
              <CollaboratorForm
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
                addFormClass={true}
                afterSave={handleCollabAfterSave}
                setSideBar={setSideBar}
                nbCollaborators={nbCollaborators}
                setShowUpgradeModal={setShowUpgradeModal}
                noLimit={["UNIVERSITY", "COMMUNITY"].includes(fiduciary.uaMode)}
              />
            )}
            {sideBar === "COLLABORATOR_IMPORT" && (
              <CollaboratorImport
                addFormClass={true}
                afterSave={handleCollabAfterSave}
                setSideBar={setSideBar}
                nbCollaborators={nbCollaborators}
                setShowUpgradeModal={setShowUpgradeModal}
                noLimit={["UNIVERSITY", "COMMUNITY"].includes(fiduciary.uaMode)}
              />
            )}
            {sideBar === "COLLABORATOR_EXPRESS" && (
              <CollaboratorExpress
                addFormClass={true}
                setSideBar={setSideBar}
                nbCollaborators={nbCollaborators}
                setShowUpgradeModal={setShowUpgradeModal}
                afterSave={handleCollabAfterSave}
                noLimit={["UNIVERSITY", "COMMUNITY"].includes(fiduciary.uaMode)}
              />
            )}
            {sideBar === "COLLABORATOR_USER_EXPRESS" && (
              <UserExpressList addFormClass={true} setSideBar={setSideBar} />
            )}
            {sideBar === "CLIENT_FORM" && (
              <ClientForm
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
                addFormClass={true}
                afterSave={handleClientAfterSave}
                setSideBar={setSideBar}
              />
            )}
            {sideBar === "CLIENT_IMPORT" && (
              <ClientImport
                addFormClass={true}
                afterSave={handleClientAfterSave}
                setSideBar={setSideBar}
              />
            )}
            {sideBar === "CLIENT_EXPRESS" && (
              <ClientExpress addFormClass={true} setSideBar={setSideBar} />
            )}
            {sideBar === "CONTACT_FORM" &&
              user.role.type !== "LEGAL_REPRESENTATIVE" && (
                <ContactForm
                  addFormClass={true}
                  currentItem={currentItem}
                  emailValue={emailValue}
                  setCurrentItem={setCurrentItem}
                  afterSave={handleContactAfterSave}
                  setSideBar={setSideBar}
                />)}
            {sideBar === "CONTACT_IMPORT" && (
              <ContactImport
                addFormClass={true}
                afterSave={handleContactAfterSave}
                setSideBar={setSideBar}
              />
            )}
            {sideBar === "MEMBERSHIP_FORM" && (
              <MembershipForm
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
                addFormClass={true}
                afterSave={handleMembershipAfterSave}
                setSideBar={setSideBar}
              />
            )}
          </div>
          <div className="cell small-12 medium-6 large-8">
            <div className={styles.content}>
              <h1 className={styles.title}>Mon compte United Associates</h1>
              <p className={styles.subtitle}>
                Gérez vos applications et vos informations d’entreprise
              </p>
              <Tabs
                theme="flex-theme"
                onChangeTab={(value) => {
                  setSideBar("DEFAULT");
                  switch (mainTabs[value]) {
                    case "apps":
                      setOpenedTabs({ ...openedTabs, apps: true });
                      break;
                    case "collaborators":
                      setOpenedTabs({ ...openedTabs, collaborators: true });
                      break;
                    case "customer_records":
                      setOpenedTabs({ ...openedTabs, customer_records: true });
                      break;
                    case "other_contacts":
                      setOpenedTabs({ ...openedTabs, other_contacts: true });
                      break;
                    case "memberships":
                      setOpenedTabs({ ...openedTabs, memberships: true });
                    // case "specialty":
                    //   setOpenedTabs({ ...openedTabs, specialty: true });
                    //   break;
                    default:
                      break;
                  }
                }}
              >
                {mainTabs.map((l, i) => (
                  <div
                    key={`maintab${i}`}
                    label={
                      l === "collaborators" && fiduciary.uaMode === "UNIVERSITY"
                        ? _("students")
                        : _(l)
                    }
                  >
                    {renderTab(l)}
                  </div>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
        />
        {isOpen && <Sidebar
          isOpened={isOpen}
          hideSidebar={() => {
            setIsOpen(false);
          }}
          currentItem={currentItem}
        />}
      </div>
    </>
  );
};

export default Settings;
