import React, { useCallback, useState } from "react";

import {
  AdvancedMarker,
  APIProvider,
  InfoWindow,
  Map,
  Pin,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";

import { GOOGLE_MAP_API_KEY } from "../../config";

import styles from "./OeccbbHotels.module.scss";

const MapView = ({ hotel, markers }) => {
  const [hoverId, setHoverId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedInfoBox, setSelectedInfoBox] = useState(null);

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  let Z_INDEX_SELECTED = markers.length;
  let Z_INDEX_HOVER = markers.length + 1;

  const onMouseEnter = useCallback((id) => setHoverId(id), []);
  const onMouseLeave = useCallback(() => setHoverId(null), []);
  const onMarkerClick = useCallback(
    (id, marker, infoboxOption) => {
      setSelectedId(id);
      setSelectedInfoBox(infoboxOption);

      if (marker) {
        setSelectedMarker(marker);
      }

      if (id !== selectedId) {
        setInfoWindowShown(true);
      } else {
        setInfoWindowShown((isShown) => !isShown);
      }
    },
    [selectedId]
  );

  const onMapClick = useCallback(() => {
    setSelectedId(null);
    setSelectedMarker(null);
    setInfoWindowShown(false);
  }, []);

  const handleInfowindowCloseClick = useCallback(
    () => setInfoWindowShown(false),
    []
  );

  return (
    <APIProvider apiKey={GOOGLE_MAP_API_KEY} libraries={["marker"]}>
      <Map
        mapId={"bf51a910020fa25a"}
        defaultZoom={12}
        center={{
          lat: parseFloat(hotel.latitude),
          lng: parseFloat(hotel.longitude),
        }}
        gestureHandling={"greedy"}
        onClick={onMapClick}
        clickableIcons={false}
        disableDefaultUI
      >
        <AdvancedMarkerWithRef
          onMarkerClick={(marker) =>
            onMarkerClick(hotel.value, marker, {
              title: hotel.label,
              description: hotel.address,
            })
          }
          onMouseEnter={() => onMouseEnter(hotel.value)}
          onMouseLeave={onMouseLeave}
          key={hotel.value}
          zIndex={0}
          className={styles.customMarker}
          style={{
            transform: `scale(${1.4})`,
          }}
          position={{
            lat: parseFloat(hotel.latitude),
            lng: parseFloat(hotel.longitude),
          }}
        >
          <Pin
            background={"#22ccff"}
            borderColor={"#1e89a1"}
            glyphColor={"#0f677a"}
          />
        </AdvancedMarkerWithRef>
        {markers.map(
          ({ id, zIndex: zIndexDefault, position, infoboxOption }) => {
            let zIndex = zIndexDefault;

            if (hoverId === id) {
              zIndex = Z_INDEX_HOVER;
            }

            if (selectedId === id) {
              zIndex = Z_INDEX_SELECTED;
            }

            return (
              <AdvancedMarkerWithRef
                onMarkerClick={(marker) =>
                  onMarkerClick(id, marker, infoboxOption)
                }
                onMouseEnter={() => onMouseEnter(id)}
                onMouseLeave={onMouseLeave}
                key={id}
                zIndex={zIndex}
                className={styles.customMarker}
                style={{
                  transform: `scale(${
                    [hoverId, selectedId].includes(id) ? 1.4 : 1
                  })`,
                }}
                position={position}
              >
                <Pin />
              </AdvancedMarkerWithRef>
            );
          }
        )}

        {infoWindowShown && selectedMarker && (
          <InfoWindow
            anchor={selectedMarker}
            onCloseClick={handleInfowindowCloseClick}
          >
            <h2>{selectedInfoBox.title}</h2>
            <p>{selectedInfoBox.description}</p>
            <p>Distance: {selectedInfoBox.distance}</p>
          </InfoWindow>
        )}
      </Map>
    </APIProvider>
  );
};

export default MapView;

export const AdvancedMarkerWithRef = (props) => {
  const { children, onMarkerClick, ...advancedMarkerProps } = props;
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <AdvancedMarker
      onClick={() => {
        if (marker) {
          onMarkerClick(marker);
        }
      }}
      ref={markerRef}
      {...advancedMarkerProps}
    >
      {children}
    </AdvancedMarker>
  );
};
