import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import "antd/dist/antd.css";

import "./styles/index.scss";
import App from "./App";
import { store } from "./store";

// import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://3863b8decd174d1886daec798156cee2@sentry.tamtam.pro/20"});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
