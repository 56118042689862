import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";

import _ from "../../i18n";
import { login } from "../../store";

const HomeRedirect = () => {
  const auth = useSelector((state) => state.auth);
  const folder = useSelector((state) => state.folder);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.user && folder.fetched) {
      if (!folder.fiduciary) {
        navigate("/create-office");
      } else {
        if (auth.user && auth.user.role) {
          if (auth.user.role.typeStatus === "FOLDER") {
            navigate("/client");
            return null;
          } else {
            if (folder?.fiduciary?.uaMode === "COMMUNITY") {
              navigate("/settings");
            } else {
              navigate("/collaborator");
            }

            return null;
          }
        }
      }
    }
  }, [auth, folder]);

  useEffect(() => {
    dispatch(login());

    if (!auth.user) {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
  }, []);

  return null;
};

export default HomeRedirect;
